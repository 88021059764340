.background-contact {
    background-image: url("../../utils/images/parallax/contact.jpg");
}

.contact-container {
    margin: 10vh 0 5vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    line-height: 3.2vh;
    text-align: justify
}

 h2 {

     letter-spacing: 2px;
    font-size: 3rem;
}

 .contact-container > h2 {
     margin-top: 10vh;
     margin-bottom: 5vh;
 }
.location-group > h2 {
    margin-top: 5vh;
    margin-bottom: 3vh;
}

.contact-container > p {
    width: 50vw;
    text-align: justify;
}

.contact-container > button, .devis-form button {
    padding: 1.5vh 1vw;
    font-size: 1.5rem;
    margin-top: 3vh;
    text-transform: uppercase;
    border: 1px solid transparent;
}

@media (max-width: 768px) {

    .contact-container h2 {
        line-height: 6vh;
        width: 84vw;
        text-align: center;
    }

    .contact-container > p {
        width: 80vw;
    }

}