.custom-carousel {
    width: 45vw;
    height: 450px;
}

/* div containing arrows to navigate */
.css-1qzevvg img:first-child {
    content: url("../../utils/images/left-arrow.JPG");
    position: relative;
    left: -300%;
    top: -680%;
    cursor: pointer;
}

.css-1qzevvg img:nth-child(2) {
    content: url("../../utils/images/right-arrow.JPG");
    position: relative;
    right: -300%;
    top: -680%;
    cursor: pointer;
}

@media (max-width: 1800px) {

    .css-1qzevvg img:first-child {
        left: -350%
    }

    .css-1qzevvg img:nth-child(2) {
        right: -350%;
    }

}

@media (max-width: 1400px) {

    .css-1qzevvg img:first-child {
        left: -400%
    }

    .css-1qzevvg img:nth-child(2) {
        right: -400%;
    }

}

@media (max-width: 1100px) {

    .css-1qzevvg img:first-child {
        left: -430%
    }

    .css-1qzevvg img:nth-child(2) {
        right: -430%;
    }

}

@media (max-width: 768px) {
    .custom-carousel {
        width: 80vw;
        height: 200px;
        overflow: hidden;
    }
}