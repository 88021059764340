.accueil-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.background-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 55vh;
}

.background-accueil {
    background-image: url("../../utils/images/parallax/accueil.jpg");
}

.logo {
    height: 40vh;
    position: absolute;
    margin: 0 auto;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    z-index: 10;
}

h1, h1 + p, h2 {
    text-align: center;
}

h1, h2, h3 {
    font-family: shopia, sans-serif;
    font-weight: normal;
}

h1#bienvenue {
    margin-top: 8vh;
}

h1#bienvenue + h2 {
    margin-top: -1vh;
    letter-spacing: 2px;
}

h1#bienvenue, h2#engagements, h2#ils-nous-font-confiance, .contact-informations h2 {
    font-size: 3rem;
    letter-spacing: 2px;
}

.engagements h3, h1#bienvenue + h2 {
    font-size: 2.2rem;
}

#bienvenue + p {
    width: 50vw;
    text-align: justify;
    margin: 3vh 0;
}

.description-entreprise {
    width: 50vw;
    margin: 2vh 0 3vh 0;
    text-align: justify;
}

.sided-images {
    width: 65vw;
    margin-top: 6vh;
    margin-bottom: 10vh;
    display: flex;
    justify-content: space-evenly;
}

.sided-images img {
    width: 30vw;
    height: 38vh;
    object-fit: cover;
}

/* ENGAGEMENTS */


h2#engagements {
    margin-bottom: 3vh;
}

.engagements {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;
}

.engagements img {
    width: 19%;
    height: 19%;
}

.engagements h3 {
    margin-bottom: 1.5vh;
}

.engagements section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 6vh;
    max-width: 50vw;
}

.engagements section > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.engagements section p {
    text-align: justify;
    width: 40vw;
}

.engagements section:nth-child(even) p {
    margin-right: 2vw;
}

.engagements section:nth-child(odd) p {
    margin-left: 2vw;
}

/* ILS NOUS FONT CONFIANCE */

.carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h2#ils-nous-font-confiance {
    margin: 6vh 0 3vh 0;
}

@media (max-width: 768px) {

    h1#bienvenue  {
        margin-top: 4vh;
    }

    .description-entreprise + button {
        margin: 2vh 0;
    }

    div.background-image {
        width: 100vw;
        height: 30vh;
    }

    .logo {
        height: 20vh;
        top: 24%;
    }

    .description-entreprise, .sided-images, .sided-images img, .engagements {
        width: 80vw;
    }

    .sided-images img {
        height: 40vh;
    }

    .sided-images img:first-child {
        margin: 3vh 0 2vh 0;
    }

    .sided-images img:last-child {
        margin: 1vh 0 2vh 0;
    }

    .sided-images, .engagements section:nth-child(odd) {
        flex-direction: column;
    }

    .engagements section:nth-child(even) {
        flex-direction: column-reverse;
    }

    .engagements section p {
        width: inherit;
    }

    .engagements section {
        margin-bottom: 4vh;
        max-width: inherit;
    }

    .engagements h3 {
        text-align: center;
    }

    .engagements img {
        width: 30vw;
        margin-bottom: 2vh;
    }

    #engagements {
        margin-top: 2vh;
    }

}
