.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    background-color: #fefefe;
    margin: 15% auto;
    padding: 3vh 3vw;
    border: 1px solid #888;
    width: 20vw;
    height: fit-content;
}

.modal-content p {
    text-align: justify;
    font-size: 1.1rem;
}

/* The Close Button */
.close {
    margin-top: 3vh;
    color: white;
}

.close:hover, .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.form-container .visible {
    display: block;
}

.form-container .invisible {
    display: none;
}

@media (max-width: 768px) {

    .modal-content {
        width: 80vw;
        height: fit-content;
        margin: 50% auto;
    }

    .close {
        margin-left: 3vw;
    }
}