/**
  --light-salmon-color: #ffebd4;
  --salmon-color: #fbcfa6;
  --blue-color: #2e6685
**/

@font-face {
    font-family: shopia;
    src: local("shopia"),
    url("utils/font/shopia.ttf") format("truetype");
}

@font-face {
    font-family: bookends;
    font-weight: normal;
    src: local("bookends"),
    url("utils/font/bookends.otf") format("opentype");
}

@font-face {
    font-family: montserrat_light;
    font-weight: normal;
    src: local("montserrat_light"),
    url("utils/font/montserrat_light.ttf") format("truetype");
}

@font-face {
    font-family: montserrat_regular;
    font-weight: normal;
    src: local("montserrat_regular"),
    url("utils/font/montserrat_regular.ttf") format("truetype");
}

* {
    margin: 0;
    padding: 0;
}

body {
    position: absolute;
    width: 100%;
    height: 100%;
    font-family: 'montserrat_light', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    line-height: 25px;
}

:visited {
    color: black;
}

button {
    border: none;
}

fieldset, textarea {
    border: none;
}

textarea {
    resize: none;
}

a {
    text-decoration: none;
    color: inherit;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
    list-style-type: none;
}


input:focus-visible, textarea:focus-visible {
    outline: none;
}