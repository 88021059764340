.services-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.top-background-services {
    background-image: url("../../utils/images/parallax/services_top.jpg");
}

.bottom-background-services {
    background-image: url("../../utils/images/parallax/services_bottom.jpg");
    background-position: center left;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
}

.bottom-background-services div {
    background-color: #ffebd4;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vh 7vw;
    width: 50vw;
}

.particuliers-container, .professionnels-container {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.services-container button {
    width: fit-content;
    margin-top: 5vh;
}

h2#professionnels, h2#particuliers {
    font-size: 3rem;
    letter-spacing: 2px;
}

h2#professionnels {
    margin: 10vh 0 3vh 0;
}

h2#particuliers {
    margin-bottom: 3vh;
}

#professionnels + p, #particuliers + p {
    width: 50vw;
    text-align: justify;
}

.professionnels-container button {
    margin-bottom: 10vh;
}

.services-container .contact-informations {
    width: 55vw;
}

@media (max-width: 768px) {

    .professionnels-container {
        width: 90vw;
    }

    h2#professionnels {
        margin-top: 5vh;
    }

    #professionnels + p {
        width: 80vw;
    }

    .bottom-background-services div {
        padding: 2vh 3vw;
        width: 80vw;
    }

    .bottom-background-services {
        height: 80vh;
    }

    #particuliers + p {
        width: 70vw;
    }

    .particuliers-container button {
        margin: 2vh 0;
    }

    .professionnels-container button {
        margin-bottom: 5vh;
    }

    .services-container .contact-informations {
        width: 80vw;
    }

    .services-container .contact-buttons {
        align-items: center;
    }

    .services-container .contact-buttons button {
        width: 40vw;
        margin-top: 3vh;
    }

}