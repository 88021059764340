footer {
    text-align: center;
    background-color: #ffebd4;
    font-size: 0.75rem;
    padding: 5vh 0;
}

footer p {
    line-height: 18px;
}

.social-medias img {
    height: 3vh;
    margin-right: 1vw;
}

.social-medias {
    margin-top: 2.5vh;
}

@media (max-width: 768px) {

    footer {
        padding: 5vh 3vw;
    }

    .social-medias img {
        height: 3vh;
        margin-right: 4vw;
    }

}