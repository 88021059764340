.background-a-propos {
    background-image: url("../../utils/images/parallax/a_propos.jpg");
}

.a-propos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.member-container {
    width: 70vw;
    margin-bottom: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.elisabeth {
    height: 74vh;
}
.pierre {

    height: 80vh;
}


 {
    margin-top: 15vh;
    height: 65vh;
}
.sylvain {
    height: 90vh;
}

h2#lille{
    margin: 10vh 0;
}
h2#vannes {
    margin-top: 4vh;
    margin-bottom: 10vh;
}

.a-propos-container h3 {
    margin-bottom: 1vh;
}

h2#lille, h2#vannes, .a-propos-container h3, h2#nous-rejoindre {
    font-size: 3rem;
    letter-spacing: 2px;
}

.description-elisabeth h3 {
    text-align: end;
}

.description-pierre h3 {
    align-self: start;
}
 h3 {
    text-align: end;
}
.description-sylvain h3 {
    text-align: start;
}

.member-container p {
    text-align: justify;
    line-height: 3.2vh;
}

.portrait {
    height: 55vh;
    margin: 0 3vw
}

.motif, .devanture {
    position: absolute;
}

.image-container {
    display: flex;
    position: relative;
}

.elisabeth .image-container {
    align-self: start;
}
.pierre .image-container {
    align-self: end;
}

 .image-container {
    align-self: start;
}
.sylvain .image-container {
    align-self: end;
}

.devanture {
    height: 30%;
    width: auto;
    max-height: 30vh;
}

.devanture-top {
    right: -20%;
}

.motif-top {
    z-index: -1;
    top: -10%;
    right: -23%;
    height: 55%;
    width: auto;
    max-height: 28vh;
    transform: rotate(180deg);
}

/* top */

.description-elisabeth {
    align-self: end;
    width: 18vw;
}
.description-pierre {
    width: 22vw;
    align-self: start;
}

 {
    width: 21vw;


}
.description-sylvain {
    width: 24vw;
    align-self: start;
}


.devanture-bottom {
    bottom: 0;
    left: -4vw;
    transform: scaleX(-1);
}
.location-group {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.location-group span {
    margin-bottom: 5vh; /* Adds space between the location name and the button */
}

.motif-bottom {
    rotate: -160deg;
    height: 23vh;
    transform: scaleX(-1);
    z-index: -1;
    right: 15vw;
    bottom: 2vh;
}


.nous-rejoindre-container {
    width: 45vw;
    background-color: #2e6685;
    color: white;
    text-align: justify;
    padding: 7vh 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10vh 0;
}

h2#nous-rejoindre {
    margin-bottom: 3vh;
}

.nous-rejoindre-container button {
    width: fit-content;
    margin-top: 5vh;
}

@media (max-width: 1804px) {

    .description-elisabeth {
        width: 24vw;
    }
, .description-sylvain {
        width: 24vw;
    }
    , .description-pierre {
        width: 24vw;
    }
}


@media (max-width: 1608px) {

    .description-elisabeth, .description-sylvain, .description-pierre{
        width: 24vw;
    }

}

@media (max-width: 1456px) {

    .description-elisabeth, .description-sylvain, .description-pierre {
        width: 26vw;
    }

}

@media (max-width: 1342px) {

    .description-elisabeth, .description-sylvain, .description-pierre {
        width: 26vw;
    }

}

@media (max-width: 1260px) {

    .description-elisabeth, .description-sylvain {
        width: 32vw;
    }
    .description-pierre {
     width:36vw;
    }

    .motif-bottom {
        right: 16vw;
    }

}


@media (max-width: 1158px) {

    .description-elisabeth {
        width: 35vw;
    }

    {
       width: 35vw;
   }

    .description-sylvain {
        width: 35vw;
    }
 .description-pierre {
        width: 35vw;
    }


    .member-container {
        width: 80vw;
    }

    .devanture-bottom {
        left: -6vw;
    }

    .motif-bottom {
        right: 18vw;
    }

}

@media (max-width: 995px) {

    .description-elisabeth, .description-sylvain, .description-pierre {
        width: 40vw;
    }

    .member-container {
        width: 90vw;
    }

    .motif-bottom {
        right: 21vw;
    }

}

@media (max-width: 844px) {

    .description-elisabeth, .description-sylvain, .description-pierre {
        width: 40vw;
    }

    .member-container {
        width: 90vw;
    }

    .devanture-top, .motif-top, .devanture-bottom, .motif-bottom {
        display: none;
    }

}

@media (max-width: 768px) {

    .member-container {
        display: flex;
        flex-direction: column;
        width: 80vw;
    }

    .elisabeth .image-container,  .image-container, .sylvain .image-container, .pierre .image-container{
        align-self: inherit;
        margin-top: 5vh;
    }

    .member-container p {
        line-height: 25px;
    }

    .description-elisabeth, .description-sylvain , .description-pierre {
        width: inherit;
    }

    .elisabeth, .sylvain, .pierre{
        height: inherit;
    }

     {
        flex-direction: column-reverse;
        margin-top: 5vw;
    }

    .member-container h3 {
        text-align: center;
        margin: 2vh 0;
    }

    h2#notre-equipe {
        margin: 5vh 0
    }

    .nous-rejoindre-container {
        width: 80vw;
        margin: 0 0 5vh 0;
    }

    .nous-rejoindre-container > p {
        width: 80vw;
    }

    .devanture-top {
        right: -22%;
        top: -2%;
    }

    .motif-top {
        bottom: 2%;
        right: -15%;
        height: 30%;
        max-height: 25vh;
    }

    .devanture-top, .motif-top, .devanture-bottom, .motif-bottom {
        display: none;
    }
}

@media (max-width: 600px) {


    .elisabeth .image-container,  .image-container, .sylvain .image-container {
        margin-top: 5vh;
    }


    .devanture-top, .motif-top, .devanture-bottom, .motif-bottom {
        display: none;
    }

}

@media (max-width: 480px) {

    .devanture-top, .motif-top, .devanture-bottom, .motif-bottom {
        display: none;
    }

}