.devis-form {
    display: flex;
    font-family: 'montserrat_regular', sans-serif;
    flex-direction: column;
    width: 40vw;
    padding: 5vh 3vw;
    margin: 7vh 0;
    background-color: #ffebd4;
}

.form-error {
    font-style: italic;
    font-size: 0.8rem;
    font-weight: bold;
}

.input-text-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 3vh;
}

.input-text-container input {
    border: none;
    height: 5vh;
    font-size: 1.2rem;
    padding: 0 0.5vw;
    font-family: 'montserrat_light', sans-serif;
}

.devis-form label, .custom-radio-input div {
    margin-bottom: 1vh;
}

.devis-form legend {
    margin-bottom: 2vh;
}

.form-description {
    font-weight: bold;
}

.first-step .form-description {
    margin-bottom: 2vh;
}

.third-step textarea {
    height: 15vh;
    border: none;
    font-size: 1rem;
    padding: 1vh 0.5vw;
    font-family: 'montserrat_light', sans-serif;
}

#other-choice {
    border: none;
    border-bottom: 1px solid black;
    background: inherit;
    margin-left: 0.5vw;
    width: 35vw;
    font-size: 1rem;
    font-family: 'montserrat_light', sans-serif;
}

form.devis-form button {
    color: white;
}

.page-counter {
    display: flex;
    justify-self: flex-end;
}

.page-counter p {
    color: #2e6685;
    font-size: 0.8rem;
    margin-left: 1vw;
    font-weight: bold;
}

.form-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: baseline;
}

.navigation-buttons {
    grid-column-start: 2;
    display: flex;
}

.prev-button {
    margin-right: 2vw;
}

.form-buttons:nth-child(3) {
    margin-left: auto;
}

.dot-navigation {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.dot-navigation-item {
    border-radius: 9999px;
    height: 0.65rem;
    width: 0.65rem;
    background-color: transparent;
    border: 1px solid #2e6685;
    margin: 0 0.25rem;
}

.dot-active {
    background-color: #2e6685;
}

.custom-radio-input input[type=radio] {
    display: none;
}

.custom-radio-input input[type="radio"] + *::before {
    content: "";
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.5vw;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.1rem;
    border-color: black;
}

.custom-radio-input input[type="radio"]:checked + *::before {
    background: black;
    border-color: black;
}

.custom-radio-input input[type="radio"]:checked + * {
    border-color: black;
}

@media (max-width: 768px) {

    .devis-form {
        width: 80vw;
    }

    .custom-radio-input input[type="radio"] + *::before {
        margin-right: 3vw;
    }
}