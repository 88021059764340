.contact-informations {
    width: 70vw;
    background-color: #2e6685;
    color: white;
    text-align: center;
    padding: 5vh 0;
    margin: 10vh 0;
}

.contact-informations h2 {
    margin-bottom: 1vh;
}

.contact-informations h2 + p {
    margin-bottom: 1vh;
}

.contact-informations p strong {
    font-weight: 600;
}

.contact-buttons {
    display: flex;
    justify-content: center;
}

.contact-buttons button {
    padding: 1.5vh 1vw;
    font-size: 1.5rem;
    margin-top: 3vh;
    text-transform: uppercase;
}

.phone-button {
    border: 1px solid #fbcfa6;
    background-color: #fbcfa6;
    color: black;
    margin-right: 1vw;
}

.mail-button {
    background-color: white;
    border: 1px solid white;
    color: black;
}

.mail-button:hover {
    background-color: #2e6685;
    border: 1px solid white;
}

.phone-button:hover {
    background-color: #2e6685;
    border: 1px solid white;
}

.mail-button:hover a {
    color: white;
}

@media (max-width: 768px) {

    .contact-informations {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80vw;
        padding: 5vh 2vw;
        margin: 5vh 0;
    }

    .contact-buttons {
        flex-direction: column;
        align-items: center;
        width: 50vw;
    }

    .contact-buttons button {
        width: 40vw;
    }

}