.button {
    text-transform: uppercase;
    font-family: bookends, sans-serif;
    font-size: 1.5rem;
    letter-spacing: 3px;
    padding: 1vh 1vw;
    border: 1px solid transparent;
    margin: 1vh;
}

.salmon-button a, .white-button a {
    color: black;
}

.blue-button {
    cursor: pointer;
    background-color: #2e6685;
}

.button-container {
    margin-top : 4vh;
    display: flex;
    gap: 10vh;
}

.blue-button a {
    color: white;
}

.blue-button:hover {
    background-color: white;
    color: #2e6685;
    border: 1px solid #2e6685;
}

.blue-button:hover a, .white-button:hover a {
    color: #2e6685;
}

.light-salmon-button {
    background-color: #f9ecd9;
}

.light-salmon-button:hover {
    background-color: white;
    color: #ffebd4;
    border: 1px solid #ffebd4;
}

.salmon-button {
    background-color: #ffebd4;
}

.salmon-button:hover {
    background-color: white;
    color: #ffebd4;
    border: 1px solid #ffebd4;
}

.salmon-button:hover a {
    color: #ffebd4;
}

.white-button {
    background-color: white;
}

.white-button:hover {
    border: 1px solid #2e6685;
}

@media (max-width: 400px) {
    .button-container {
        display: inline;
    }
}