.top-navbar {
    display: flex;
    justify-content: center;
    margin: 3vh 0;
}

.nav-links {
    display: flex;
}

.has-dropdown > a:after {
    content: "";
    width: 0;
    height: 1px;
    display: block;
    transition: all 0.3s ease;
}

.has-dropdown > a:hover::after {
    width: 100%;
    height: 1px;
    background-color: black;
}

.top-navbar li {
    margin: 0 0.7vw;
    font-family: bookends, sans-serif;
    font-size: 2.4rem;
}

.has-dropdown {
    position: relative;
}

.bottom-border {
    border-bottom: 1px solid black;
}

.dropdown {
    display: none;
    position: absolute;
    background-color: white;
    opacity: 0.9;
    z-index: 1;
}

.dropdown a {
    font-size: 14px;
    font-family: 'montserrat_light', sans-serif;
    width: 7vw;
    float: none;
    color: black;
    padding: 8px 8px;
    display: block;
}

.has-dropdown:hover .dropdown {
    display: block;
}

.responsive-navbar {
    display: none;
}

@media (max-width: 768px) {

    .responsive-navbar {
        display: block;
        height: 8vh;
    }

    .top-navbar {
        display: none;
    }

    .visible {
        display: block;
        z-index: 100;
    }

    .invisible {
        display: none;
    }

}