.page-wrap {
    overflow: auto;
}

.page-wrap a {
    color: black;
}

.bm-logo {
    display: block;
    height: 20vh;
    margin: 0 auto 4vh;
}

.bm-overlay + div {
    height: 0;
}

.bm-burger-button {
    position: relative;
    width: 36px;
    height: 30px;
    left: 5vw;
    top: 2vh;
}

.separator {
    border-bottom: 1px solid black;
    margin: 2vh 0;
}

/* Color/shape of burger icon bars */
span .bm-burger-bars {
    background: #373a47;
    height: 4%!important;
    width: 8vw;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #000000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 250px!important;
}

/* General sidebar styles */
.bm-menu {
    background: white;
    padding: 0;
    font-size: 2.5rem;
    font-family: bookends, sans-serif;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
